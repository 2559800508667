<template>
    <form
        @submit.prevent="validateForm(formScope)"
        :data-vv-scope="formScope"
        :ref="formScope"
    >
        <Risk
            :formScope="formScope"
            :countryCode="countryCode"
            :create="create"
            :template="template"
            @help-mode="$emit('help-mode', $event)"
        />
    </form>
</template>

<script>
// import FormMixin from '@/components/vvt/ProcessEditor/FormMixin'
import Risk from './Risk';

export default {
    name: 'Step7',
    // mixins: [FormMixin],
    components: {
        Risk,
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
    },
};
</script>
